import styled from 'styled-components/macro';

import colors from '../../GlobalStyle/colors';

export const StepsContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  max-width: 450px;
  position: relative;
  width: auto;

  &:after {
    position: absolute;
    border-top: dashed 1px ${colors.disabled};
    top: 23px;
    z-index: 0;
    width: 80%;
    left:0;
    right:0;
    margin-left:auto;
    margin-right:auto;
  }
`;

import React from 'react';
import {
  number,
  string,
  oneOf,
} from 'prop-types';

import Icon from '../../../Icons';
import * as S from './style';

export default function Step({
  description,
  stepNumber,
  status = 'pending',
  link,
  ...props
}) {
  const RenderStep = () => (
    <S.StepItem>
      <S.ButtonNumber
        color="primary"
        outline
        className="btn-icon-only"
        disabled={status === 'pending'}
        icon={status === 'sucesss' || status === 'error'}
        status={status}
      >
        {status !== 'success' && status !== 'error' && stepNumber}
        {status === 'success' && <Icon name="check" />}
        {status === 'error' && <Icon name="times" />}
      </S.ButtonNumber>
      <S.Description status={status}>
        {description}
      </S.Description>
    </S.StepItem>
  );

  return (
    <>
      {status === 'success'
      && (
        <S.StepLink to={link} {...props} status={status}>
          <RenderStep />
        </S.StepLink>
      )}
      {status !== 'success'
      && <RenderStep />}
    </>
  );
}

Step.propTypes = {
  description: string.isRequired,
  link: string.isRequired,
  stepNumber: number.isRequired,
  status: oneOf([
    'current',
    'error',
    'pending',
    'success',
  ]),
};

import React, { useState, useEffect } from 'react';
import { useNavigate } from '@reach/router';
import { gml } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import Button from '../../../../components/Button';
import Steps from '../../../../components/alright/StepsBlocks';
import { useInstallationsBlocks } from '../../../../contexts/installationsContextBlocks';

import * as S from './style';
import { PAGES } from '../../../../constants';
import { blocksTags } from '../stepsList';
import Toast from '../../../../components/Toast';
import ModalAlert from '../../../../components/alright/ModalAlert';
import ErrorMessage from '../../ErrorMessage';
import { useGlobal } from '../../../../contexts/GlobalContext';
import ComponentLoader from '../../../../components/alright/ComponentLoader';
import DivPlugin from '../DocsBlocks/Div-Plugin.pdf';
import DivTema from '../DocsBlocks/Div-tema.pdf';
import DivWordpress from '../DocsBlocks/Div-Wordpress.pdf';

export default function Install() {
  const [copyStatusVideo, setCopyStatusVideo] = useState(false);
  const [copyStatusDisplay, setCopyStatusDisplay] = useState(false);
  const [copyStatusNative, setCopyStatusNative] = useState(false);
  const [copyStatusBanner, setCopyStatusBanner] = useState(false);
  const [copyStatusBannerScript, setCopyStatusBannerScript] = useState(false);
  const [copyStatusBannerFixo, setCopyStatusBannerFixo] = useState(false);
  const [copyStatusFlyingCarpet, setCopyStatusFlyingCarpet] = useState(false);
  const [copyStatusFlyingScript, setCopyStatusFlyingScript] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);
  const [toast, setToast] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { setIsUserLogged } = useGlobal();
  const {
    manualSteps,
    setManualSteps,
    postPublisherStageBlocks,
  } = useInstallationsBlocks();

  useEffect(() => {
    setManualSteps(manualSteps, 0, 'success');
  }, [setManualSteps, manualSteps]);

  const openModalStatus = () => {
    setModalStatus(true);
  };

  const closeModalStatus = () => {
    setModalStatus(false);
  };

  const activateError = () => {
    setManualSteps(manualSteps, 0, 'error');
    closeModalStatus();
  };

  const confirmModal = () => {
    setLoading(true);
    setIsUserLogged(true);
    navigate(PAGES.ROOT());
    return activateError();
  };

  const finishStep = async () => {
    setLoading(true);
    setIsUserLogged(true);
    postPublisherStageBlocks();
    openModalStatus();
  };

  const onCopyCodeToast = () => {
    setToast(true);
    setTimeout(() => {
      setToast(false);
    }, 3000);
  };

  const onCopyVideo = () => {
    setCopyStatusVideo(true);
    setTimeout(() => {
      setCopyStatusVideo(false);
    }, 3000);
  };
  const onCopyDisplay = () => {
    setCopyStatusDisplay(true);
    setTimeout(() => {
      setCopyStatusDisplay(false);
    }, 3000);
  };
  const onCopyNative = () => {
    setCopyStatusNative(true);
    setTimeout(() => {
      setCopyStatusNative(false);
    }, 3000);
  };
  const onCopyBanner = () => {
    setCopyStatusBanner(true);
    setTimeout(() => {
      setCopyStatusBanner(false);
    }, 3000);
  };
  const onCopyBannerScript = () => {
    setCopyStatusBannerScript(true);
    setTimeout(() => {
      setCopyStatusBannerScript(false);
    }, 3000);
  };
  const onCopyBannerFixo = () => {
    setCopyStatusBannerFixo(true);
    setTimeout(() => {
      setCopyStatusBannerFixo(false);
    }, 3000);
  };
  const onCopyFlyingScript = () => {
    setCopyStatusFlyingScript(true);
    setTimeout(() => {
      setCopyStatusFlyingScript(false);
    }, 3000);
  };
  const onCopyFlyingCarpet = () => {
    setCopyStatusFlyingCarpet(true);
    setTimeout(() => {
      setCopyStatusFlyingCarpet(false);
    }, 3000);
  };

  return (
    <>
      <Steps list={manualSteps} />
      { manualSteps[0].status === 'error' && (
        <ErrorMessage
          title="Ops! Algo deu errado na instalação. :( "
          description="Certifique-se que esteja instalando o código no local correto."
        />
      )}
      { toast && (
      <Toast title="Código copiado com sucesso! " color="success" />
      )}
      <S.Disclaimer>
        <S.h2>Parabéns Publisher!</S.h2>
        <S.Disclaimer>
          Seguimos então para a última etapa de instalações, os  blocos de anúncio.
          Estes blocos são responsáveis pela monetização dos anúncios no seu site!
          Lembrando que os formatos entregues serão Banner fixo + vídeo e Display.
        </S.Disclaimer>
        <S.Disclaimer>
          O Banner Fixo já está automaticamente inserido assim que você instala nossa TAG.
          É necessário apenas instalar o formato de Vídeo, para isso siga os passos:
        </S.Disclaimer>
        <S.Disclaimer>
          Copie o código do formato VÍDEO clicando no botão &quot;
          <strong>Copiar Video</strong>
          &quot; e posicione no local desejado.
          Sugerimos entre parágrafos do artigo ou antes do conteúdo.
        </S.Disclaimer>
        { blocksTags.tag_video && (
        <CopyToClipboard
          text={blocksTags.tag_video}
          onCopy={onCopyCodeToast}
        >
          <S.Code style={gml}>
            {blocksTags.tag_video}
          </S.Code>
        </CopyToClipboard>
        )}
        <S.ButtonLine>
          <CopyToClipboard
            text={blocksTags.tag_video}
            onCopy={onCopyVideo}
          >
            <Button
              color="primary"
              outline
            >
              {copyStatusVideo
                ? 'Tag Vídeo copiada!'
                : 'Copiar Vídeo_alright'}
            </Button>
          </CopyToClipboard>
        </S.ButtonLine>
        <S.Disclaimer>
          Para posicionar o formato DISPLAY clique no botão &quot;
          <strong>Copiar Display</strong>
          &quot; e posicione no local que desejar.
          Como sugestão você pode posicionar o bloco de anúncio formato display na página,
          caso tenha mais de uma posição, você pode adicionar mais de uma posição no seu site.
          Basta adicionar o bloco de anúncio nas posições desejadas.
          Vale lembrar que é preciso numerar cada bloco.
        </S.Disclaimer>
        { blocksTags.tag_display && (
        <CopyToClipboard
          text={blocksTags.tag_display}
          onCopy={onCopyCodeToast}
        >
          <S.Code style={gml}>
            {blocksTags.tag_display}
          </S.Code>
        </CopyToClipboard>
        )}
        <S.ButtonLine>
          <CopyToClipboard
            text={blocksTags.tag_display}
            onCopy={onCopyDisplay}
          >
            <Button
              color="primary"
              outline
            >
              {copyStatusDisplay
                ? 'Tag Display copiada!'
                : 'Copiar Display'}
            </Button>
          </CopyToClipboard>
        </S.ButtonLine>
        <br />
        <S.Disclaimer>
          Posicione também o formato Multiplex do Google, no final do conteúdo,
          copiar no botão &quot;
          <strong> Copiar Native</strong>
          &quot;.
        </S.Disclaimer>
        <S.Disclaimer>
          O Multiplex são grides de anúncios nativos que maximizam receita e experiência do usuário.
          Eles são um substituto brand-safe para anúncios de recomendação de conteúdo.
        </S.Disclaimer>
        { blocksTags.tag_native && (
        <CopyToClipboard
          text={blocksTags.tag_native}
          onCopy={onCopyCodeToast}
        >
          <S.Code style={gml}>
            {blocksTags.tag_native}
          </S.Code>
        </CopyToClipboard>
        )}
        <S.ButtonLine>
          <CopyToClipboard
            text={blocksTags.tag_native}
            onCopy={onCopyNative}
          >
            <Button
              color="primary"
              outline
            >
              {copyStatusNative
                ? 'Tag Native copiada!'
                : 'Copiar Native'}
            </Button>
          </CopyToClipboard>
        </S.ButtonLine>
        <S.Disclaimer>
          Não sabe como fazer isso?
          Temos aqui 3 sugestões de como você pode posicionar o código:
        </S.Disclaimer>
        <S.Disclaimer>
          Clique na opção que deseja para baixar o pdf com o passo-a-passo de como
          realizar esta instalação.
        </S.Disclaimer>
        <S.ButtonLine>
          <S.DownloadLink
            type="button"
            href={DivWordpress}
            target="blank"
          >
            WORDPRESS - NEWSPAPER
          </S.DownloadLink>
          <S.DownloadLink
            type="button"
            href={DivTema}
            target="blank"
          >
            DIRETO No Arquivo do tema( tema padrão)
          </S.DownloadLink>
          <S.DownloadLink
            type="button"
            href={DivPlugin}
            target="blank"
          >
            Ou através de um PLUGIN
          </S.DownloadLink>
        </S.ButtonLine>
      </S.Disclaimer>
      <S.h2>Para inserir modelos de Bloco AMP</S.h2>
      <S.Disclaimer>
        Este é um passo bem importante para sua completa monetização conosco. As páginas
        AMP possuem um funcionamento particular e, por tanto, é necessário que a instalação seja
        feita de forma separada dos demais tipos de página (desktop e mobile). Fica tranquilo que
        eu vou te ajudar com isso.
      </S.Disclaimer>
      <S.Disclaimer>
        Para esse tipo de página temos três opções de blocos que
        você pode inserir:

      </S.Disclaimer>
      <S.Disclaimer>1-Bloco de anúncios comum</S.Disclaimer>
      <S.Disclaimer>2-Banner fixo</S.Disclaimer>
      <S.Disclaimer>3-E o flying carpet (Ideal que seja inserido no meio do conteúdo)</S.Disclaimer>
      <S.Disclaimer>
        Você não precisa escolher um dos três formatos para realizar a instalação. É
        possível instalar os três em uma mesma página, inclusive, para uma melhor
        monetização, nós recomendamos que você instale ambos os formatos.
        Abaixo estamos disponibilizando os três códigos, basta copiar e colar no back end do
        site:
      </S.Disclaimer>
      <S.Disclaimer>
        Se você não possui AMP, será necessário que utilize um
        Plugin para isso.

      </S.Disclaimer>
      <S.Disclaimer>
        Abaixo estamos disponibilizando as três tags AMP, basta copiar e colar,
        conforme as instruções no documento, no template das páginas ou no plugin utilizado:

      </S.Disclaimer>
      <S.h2>Bloco de anúncios comum</S.h2>
      <S.Disclaimer>
        Copie o código do formato Blocos de Anuncio Comum clicando no botão &quot;
        <strong>Blocos-Comum</strong>
        &quot; e posicione &quot;
        <strong>&lt;!-- Body -- ONDE DEVE CARREGAR O ANUNCIO&gt;</strong>
        &quot; no seu site.
      </S.Disclaimer>
      { blocksTags.Blocos_Comum && (
        <CopyToClipboard
          text={blocksTags.Blocos_Comum}
          onCopy={onCopyCodeToast}
        >
          <S.Code style={gml}>
            {blocksTags.Blocos_Comum}
          </S.Code>
        </CopyToClipboard>
      )}
      <S.ButtonLine>
        <CopyToClipboard
          text={blocksTags.Blocos_Comum}
          onCopy={onCopyBanner}
        >
          <Button
            color="primary"
            outline
          >
            {copyStatusBanner
              ? 'Banner copiado!'
              : 'Copiar Banner-Comum'}
          </Button>
        </CopyToClipboard>
      </S.ButtonLine>
      <S.h2>Banner Fixo</S.h2>
      <S.Disclaimer>
        Copie o código do formato BANNER FIXO clicando no botão &quot;
        <strong>Banner-Script</strong>
        &quot; e posicione em &quot;
        <strong>&lt;!--Head--&gt;</strong>
        &quot;
        e copie o código clicando no botão &quot;
        <strong>Banner-Fixo</strong>
        &quot; e posicione em &quot;
        <strong>&lt;!-- Body -- ONDE DEVE CARREGAR O ANUNCIO&gt;</strong>
        &quot;.
      </S.Disclaimer>
      { blocksTags.Banner_Script && (
        <CopyToClipboard
          text={blocksTags.Banner_Script}
          onCopy={onCopyCodeToast}
        >
          <S.Code style={gml}>
            {blocksTags.Banner_Script}
          </S.Code>
        </CopyToClipboard>
      )}
      <S.ButtonLine>
        <CopyToClipboard
          text={blocksTags.Banner_Script}
          onCopy={onCopyBannerScript}
        >
          <Button
            color="primary"
            outline
          >
            {copyStatusBannerScript
              ? 'Script copiado!'
              : 'Copiar Banner-Script'}
          </Button>
        </CopyToClipboard>
      </S.ButtonLine>
      { blocksTags.Banner_fixo && (
      <CopyToClipboard
        text={blocksTags.Banner_fixo}
        onCopy={onCopyCodeToast}
      >
        <S.Code style={gml}>
          {blocksTags.Banner_fixo}
        </S.Code>
      </CopyToClipboard>
      )}
      <S.ButtonLine>
        <CopyToClipboard
          text={blocksTags.Banner_fixo}
          onCopy={onCopyBannerFixo}
        >
          <Button
            color="primary"
            outline
          >
            {copyStatusBannerFixo
              ? 'Banner copiado!'
              : 'Copiar Banner-Fixo'}
          </Button>
        </CopyToClipboard>
      </S.ButtonLine>

      <S.h2>Flying Carpet</S.h2>
      <S.Disclaimer>
        Copie o código do formato FLYING CARPET clicando no botão &quot;
        <strong>Flying-Script</strong>
        &quot; e posicione em
        <strong>&lt;!--Head--&gt;</strong>
        e copie o código clicando no botão &quot;
        <strong>Flying-Carpet</strong>
        &quot; e posicione em &quot;
        <strong>&lt;!-- Body -- ONDE DEVE CARREGAR O ANUNCIO&gt;</strong>
        &quot;.
      </S.Disclaimer>
      { blocksTags.Flying_script && (
        <CopyToClipboard
          text={blocksTags.Flying_script}
          onCopy={onCopyCodeToast}
        >
          <S.Code style={gml}>
            {blocksTags.Flying_script}
          </S.Code>
        </CopyToClipboard>
      )}
      <S.ButtonLine>
        <CopyToClipboard
          text={blocksTags.Flying_script}
          onCopy={onCopyFlyingScript}
        >
          <Button
            color="primary"
            outline
          >
            {copyStatusFlyingScript
              ? 'Script copiado!'
              : 'Copiar Flying-Script'}
          </Button>
        </CopyToClipboard>
      </S.ButtonLine>
      { blocksTags.Flying_Carpet && (
      <CopyToClipboard
        text={blocksTags.Flying_Carpet}
        onCopy={onCopyCodeToast}
      >
        <S.Code style={gml}>
          {blocksTags.Flying_Carpet}
        </S.Code>
      </CopyToClipboard>
      )}
      <S.ButtonLine>
        <CopyToClipboard
          text={blocksTags.Flying_Carpet}
          onCopy={onCopyFlyingCarpet}
        >
          <Button
            color="primary"
            outline
          >
            {copyStatusFlyingCarpet
              ? 'Flying Carpet copiado!'
              : 'Copiar Flying Carpet'}
          </Button>
        </CopyToClipboard>
      </S.ButtonLine>
      <S.Disclaimer>
        Após concluir essas instalações você já estará gerando receita!
        Então é muito importante que conclua e nos avise se deu tudo certo!
        Finalize clicando no botão &quot;
        <strong>Confirmar instalação</strong>
        &quot;.
      </S.Disclaimer>
      <S.Disclaimer>
        Finalize clicando no botão &quot;
        <strong>Confirmar instalação</strong>
        &quot;.
      </S.Disclaimer>
      <S.ButtonLine>
        <Button
          color="primary"
          onClick={finishStep}
          outline
        >
          { loading
            ? <ComponentLoader />
            : <span>Confirmar instalação</span>}
        </Button>
      </S.ButtonLine>
      <ModalAlert
        title={blocksTags.tag_video ? 'Instalação confirmada' : 'Falha na instalação da tag_video'}
        buttonText="Ok"
        onClose={closeModalStatus}
        onConfirm={confirmModal}
        type={blocksTags.tag_video ? 'success' : 'warning'}
        showModal={modalStatus}
      />
    </>
  );
}

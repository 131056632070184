/* eslint-disable consistent-return */
import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useCallback,
} from 'react';

import {
  getPublisherData,
  getAdsTxt,
  postEtapaUpdate,
  postEmail,
  postForm,
} from '../api/installations.service';
import { useGlobal } from './GlobalContext';

const InstallationsContext = createContext();

function InstallationsProvider(props) {
  const [state, setState] = useState({
    publisherData: {},
    adsTxt: '',
    publisherForm: false,
    publisherEtapa: false,
    manualSteps: [
      {
        number: 1,
        status: 'pending',
        description: 'Instalar Tag Alright',
        link: '/instalacoes/manual/instalar',
      },
      {
        number: 2,
        status: 'pending',
        description: 'Adicionar Ads.txt',
        link: '/instalacoes/manual/ativar',
      },
      {
        number: 3,
        status: 'pending',
        description: 'Formulário do Google',
        link: '/instalacoes/manual/formulario',
      },
    ],
    wordpressSteps: [
      {
        number: 1,
        status: 'pending',
        description: 'Instalar e ativar plugin',
        link: '/instalacoes/wordpress/instalar',
      },
      {
        number: 2,
        status: 'pending',
        description: 'Ativar Tag Alright',
        link: '/instalacoes/wordpress/ativar',
      },
      {
        number: 3,
        status: 'pending',
        description: 'Formulário do Google',
        link: '/instalacoes/wordpress/formulario',
      },
    ],
  });

  const value = useMemo(() => ({
    setState,
    state,
  }), [state]);

  return (
    <InstallationsContext.Provider
      value={value}
      {...props}
    />
  );
}

function useInstallations() {
  const context = useContext(InstallationsContext);
  if (!context) {
    throw new Error('InstallationsContext deve ser usado dentro de um InstallationsProvider');
  }

  const { state, setState } = context;
  const { publisher, setRequestError } = useGlobal();

  const setPublisherData = useCallback((publisherData) => {
    setState((prevState) => ({
      ...prevState,
      publisherData,
    }));
  }, [setState]);

  const setAdsData = useCallback((adsTxt) => {
    setState((prevState) => ({
      ...prevState,
      adsTxt,
    }));
  }, [setState]);

  const getPublisher = useCallback(async () => {
    try {
      const response = await getPublisherData(publisher?.id);

      if (response) {
        setPublisherData(response);
        return response;
      }
    } catch (error) {
      if (error?.response?.status) {
        setRequestError();
      }
    }
  }, [publisher, setPublisherData, setRequestError]);

  const getPublisherAdsTxt = async () => {
    try {
      const response = await getAdsTxt(publisher?.id);
      if (response != null) {
        setAdsData(response);
      }
    } catch (error) {
      if (error?.response?.status) {
        setRequestError();
      }
    }
  };

  const setManualSteps = useCallback((manualSteps, position, status) => {
    const newArray = manualSteps;
    newArray[position].status = status;
    setState((prevState) => ({
      ...prevState,
      newArray,
    }));
  }, [setState]);

  const setWordpressSteps = useCallback((wordpressSteps, position, status) => {
    const newArray = wordpressSteps;
    newArray[position].status = status;
    setState((prevState) => ({
      ...prevState,
      newArray,
    }));
  }, [setState]);

  const postPublisherEmail = async (email) => {
    try {
      const response = await postEmail(publisher?.id, email);
      if (response != null) {
        return response;
      }
    } catch (error) {
      if (error?.response?.status) {
        setRequestError();
      }
    }
  };

  const setPublisherForm = (publisherForm) => {
    setState((prevState) => ({
      ...prevState,
      publisherForm,
    }));
  };

  const postPublisherForm = async () => {
    try {
      const response = await postForm(publisher?.id);
      if (response != null) {
        setPublisherForm(true);
      }
    } catch (error) {
      if (error?.response?.status) {
        setRequestError();
      }
    }
  };
  const setPublisherEtapa = (publisherEtapa) => {
    setState((prevState) => ({
      ...prevState,
      publisherEtapa,
    }));
  };
  const postPublisherEtapa = async () => {
    try {
      const response = await postEtapaUpdate();
      if (response != null) {
        setPublisherEtapa(true);
      }
    } catch (error) {
      if (error?.response?.status) {
        setRequestError();
      }
    }
  };

  return {
    publisherData: state.publisherData,
    getPublisher,
    adsTxt: state.adsTxt,
    getPublisherAdsTxt,
    manualSteps: state.manualSteps,
    setManualSteps,
    wordpressSteps: state.wordpressSteps,
    setWordpressSteps,
    postPublisherEmail,
    publisherForm: state.publisherForm,
    postPublisherForm,
    publisherEtapa: state.publisherEtapa,
    postPublisherEtapa,
  };
}

export { InstallationsProvider, useInstallations };

import React from 'react';

import {
  Card as ReactstrapCard,
  CardBody as ReactstrapCardBody,
  CardTitle as ReactstrapCardTitle,
} from 'reactstrap';
import { node, string } from 'prop-types';

export default function Card({
  children,
  title = '',
  titleSize = 'h2',
  ...props
}) {
  return (
    <ReactstrapCard
      {...props}
    >
      <ReactstrapCardBody>
        { title && (
          <ReactstrapCardTitle className="mb-3" tag={titleSize}>
            { title }
          </ReactstrapCardTitle>
        )}
        {children}
      </ReactstrapCardBody>
    </ReactstrapCard>
  );
}

Card.propTypes = {
  children: node,
  title: string,
  titleSize: string,
};

import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';

import App from './pages/App';
import * as serviceWorker from './serviceWorker';
import { GlobalProvider } from './contexts/GlobalContext';
import authentication from './utils/authentication';
import Loader from './components/alright/Loader';

const ROOT = document.getElementById('root');

function unmountRender(element) {
  unmountComponentAtNode(ROOT);
  render(element, ROOT);
}

unmountRender(<Loader />);

authentication()
  .then((auth) => {
    window.auth = auth;

    if (auth.token && auth.isValidToken) {
      window.localStorage.setItem('token', auth.token);
      window.localStorage.setItem('isValidToken', auth.isValidToken);
    }

    unmountRender(<GlobalProvider><App /></GlobalProvider>);
  }).catch((error) => {
    window.localStorage.setItem('error', error);
  });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

serviceWorker.unregister();

import styled from 'styled-components';

import { ReactComponent as EmailSvg } from '../../../../assets/img/icons/alright/icon-email.svg';
import colors from '../../../../components/GlobalStyle/colors';

export const EmailIcon = styled(EmailSvg)`
  margin: 50px auto 0;
  width: 100%;
`;

export const Title = styled.h2`
  color: ${colors.black};
  font-size: 30px;
  margin-top: 35px;
  text-align: center;
`;

export const Text = styled.p`
  color: ${colors.black};
  display: inline-block;
  font-size: 16px;
  line-height: 1.75;
  margin: 15px auto 0;
  text-align: center;
  width: 100%;
`;

export const ButtonLine = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  width: 100%;
`;

import styled from 'styled-components/macro';

import { ReactComponent as EmojiSuccess } from '../../../assets/img/icons/emoji-success.svg';
import { ReactComponent as EmojiError } from '../../../assets/img/icons/emoji-error.svg';
import closeIcon from '../../../assets/img/icons/icon-close.svg';
import Button from '../../Button';
import colors from '../../GlobalStyle/colors';

export const Wrapper = styled.div`
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  z-index: 5400;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.625em;
  overflow: hidden auto;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.7);
  display: ${({ show }) => (show ? 'flex' : 'none')};
`;

export const Modal = styled.div`
  animation: 300ms ease 0s 1 normal none running showSweetAlert;
  background-color: ${colors.purple};
  border-radius: 0.3125em;
  color: ${colors.black};
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  max-width: 100%;
  outline: none;
  overflow: hidden;
  padding: 1.25rem;
  position: relative;
  text-align: center;
  width: 32em;
  z-index: 5600;
`;

export const Title = styled.h2`
  color: ${colors.black};
  font-size: 22px;
  text-align: center;
  padding: 10px 0 5px;
  max-width: 78%;
  margin: 0 auto;
`;

export const Text = styled.p`
  color: ${colors.black};
  font-size: 14px;
  line-height: 1.4;
  margin: 0 auto;
  max-width: 360px;
  text-align: center;
  font-weight: 400;
`;

export const ButtonComponent = styled(Button)`
  display: block;
  border: 2px solid ${colors.black};
  border-radius: 50px;
  color: ${colors.black};
  font-size: 14px;
  font-weight: 600;
  margin: 20px auto 15px;

  &:hover {
    background-color: ${colors.black};
  }
`;
export const ButtonComponentBlocks = styled(Button)`
  display: block;
  border: 2px solid ${colors.black};
  border-radius: 50px;
  color: ${colors.black};
  font-size: 14px;
  font-weight: 600;
  margin: 20px 20px auto 160px;
  padding: 10px;
  width: 150px;

  &:hover {
    background-color: ${colors.black};
  }
`;

export const IconSuccess = styled(EmojiSuccess)`
  height: 60px;
  width: auto;
`;

export const IconError = styled(EmojiError)`
  height: 60px;
  width: auto;
`;

export const LinkButton = styled.a`
  width: 150px;
  padding: 10px;
  display: block;
  font-size: 14px;
  font-weight: 500;
  border-width: 2px;
  border: 2px solid;
  margin: 20px auto 0;
  border-radius: 50px;
  transition: .4s ease;
  -webkit-transition: .4s ease;
  border-color: ${colors.black};
  text-decoration: none !important;
  color: ${colors.black} !important;

  &:hover {
    background-color: ${colors.black};
    color: ${colors.white} !important;
  }
`;

export const ButtonClose = styled.button`
  position: absolute;
  border: none;
  top: 10px;
  cursor: pointer;
  right: 12px;
  width: 29px;
  height: 29px;
  color: ${colors.black};
  background: url(${closeIcon});
`;

import React from 'react';
import styled from 'styled-components/macro';
import {
  node,
  number,
} from 'prop-types';

import colors from '../../GlobalStyle/colors.js';
import Badge from '../../Badge';

const Line = styled.div`
  align-items: center;
  display: flex;
  width: 100%;

  &:not(:first-child) {
    border-top: solid 1px #e2e2e2;
    margin-top: 26px;
    padding-top: 20px;
  }
`;

const StepBadge = styled(Badge)`
  background-color: #f1f1f1;
  color: ${colors.black};
  font-size: 16px;
  font-weight: 400;
  padding: 12px 20px;
  text-transform: initial;
`;

const Description = styled.span`
  color: ${colors.textBlack};
  font-size: 15px;
  flex: 1;
  line-height: 1.3;
  margin-left: 30px;
  width: 160px;
`;

export default function StepLine({
  description,
  itemIndex = 1,
  ...props
}) {
  return (
    <Line
      {...props}
    >
      <StepBadge color="primary" pill>
        Passo&nbsp;
        {itemIndex}
      </StepBadge>
      <Description dangerouslySetInnerHTML={{ __html: description }} />
    </Line>
  );
}

StepLine.propTypes = {
  description: node,
  itemIndex: number,
};

/* eslint-disable camelcase */
import { request, ajax } from '../utils/request';
import { APPLICATION } from '../constants';

export const getPublisherData = (publisherId) => ajax.get(`${APPLICATION.API_URL}/publisher/${publisherId}`, {
  headers: {
    'x-token': `${window.localStorage.getItem('token')}`,
  },
}).json();

export const getAdsTxt = (publisherId) => ajax.get(`${APPLICATION.DASH_URL}/adstxt/adstxt_publisher/${publisherId}`, {
  headers: {
    HTTP_X_TOKEN: `${window.localStorage.getItem('token')}`,
  },
}).text();

export const postEmail = async (publisherId, email) => {
  const searchParams = new URLSearchParams();
  searchParams.set('email', email);
  searchParams.set('publisher_id', publisherId);

  return request.post(`${APPLICATION.API_URL}/email/installations`, {
    body: searchParams,
    headers: {
      'x-token': `${window.localStorage.getItem('token')}`,
    },
  }).text();
};

export const postForm = (publisherId) => ajax.post(`${APPLICATION.API_URL}/google-form/${publisherId}`, {
  headers: {
    'x-token': `${window.localStorage.getItem('token')}`,
  },
}).json();

export const postEmailInstallationsDone = (publisherId) => ajax.post(`${APPLICATION.API_URL}/done/${publisherId}`, {
  headers: {
    HTTP_X_TOKEN: `${window.localStorage.getItem('token')}`,
  },
}).json();

export const postEtapaUpdate = async () => {
  const publisher = JSON.parse(window.localStorage.getItem('publisher'));
  const usuario = JSON.parse(window.localStorage.getItem('usuario'));
  const etapa_cadastro_publisher_id = 2;
  const data = {
    usuario: {
      id: usuario.id.toString(),
      etapa_cadastro_publisher: etapa_cadastro_publisher_id.toString(),
      cadastro_publisher_id: publisher.id.toString(),
    },
  };
  const searchParams = new URLSearchParams();
  window.localStorage.setItem('etapaInstalacao', JSON.stringify(data));
  searchParams.set('data', JSON.stringify(data));
  return ajax.post(`${APPLICATION.API_URL}/etapaupdate`, {
    body: searchParams,
    headers: {
      HTTP_X_TOKEN: `${window.localStorage.getItem('token')}`,
      withCredentials: true,
    },
  }).json();
};

export const postStageUpdateBlocks = async () => {
  const publisher = JSON.parse(window.localStorage.getItem('publisher'));
  const usuario = JSON.parse(window.localStorage.getItem('usuario'));
  const etapa_cadastro_publisher_id = 3;
  const data = {
    usuario: {
      id: usuario.id.toString(),
      etapa_cadastro_publisher: etapa_cadastro_publisher_id.toString(),
      cadastro_publisher_id: publisher.id.toString(),
    },
  };
  window.localStorage.setItem('StageBlocks', JSON.stringify(data));
  const searchParams = new URLSearchParams();
  searchParams.set('data', JSON.stringify(data));
  return ajax.post(`${APPLICATION.API_URL}/etapaupdate`, {
    body: searchParams,
    headers: {
      HTTP_X_TOKEN: `${window.localStorage.getItem('token')}`,
      withCredentials: true,
    },
  }).json();
};

export const postCheckApprovalUrl = async () => {
  const publisherTemp = JSON.parse(window.localStorage.getItem('publisherTemp'));
  const urlApproval = publisherTemp.publisher.url;
  const resultUrl = urlApproval.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0];
  const data = {
    url: resultUrl,
  };
  return ajax.post(`${APPLICATION.ADMANAGER_URL}/check_approval/url`, {
    body: JSON.stringify(data),
  }).json();
};

export const postInventoryAdUnits = async () => {
  const publisherTemp = JSON.parse(window.localStorage.getItem('publisherTemp'));
  const urlApproval = publisherTemp.publisher.url;
  const resultUrl = urlApproval.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0];
  const data = {
    nome: publisherTemp.publisher.nome,
    url: resultUrl,
  };
  return ajax.post(`${APPLICATION.ADMANAGER_URL}/inventory/ad_units/new`, {
    body: JSON.stringify(data),
  }).json();
};

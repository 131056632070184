/* eslint-disable consistent-return */
import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useCallback,
} from 'react';

import {
  postStageUpdateBlocks,
  getPublisherData,
} from '../api/installations.service';
import { useGlobal } from './GlobalContext';

const InstallationsContextBlocks = createContext();

function InstallationsProviderBlocks(props) {
  const [state, setState] = useState({
    publisherData: {},
    manualSteps: [
      {
        number: 1,
        status: 'pending',
        description: 'Instalar Divs',
        link: '/instalacoes/manual/instalar',
      },
    ],
  });

  const value = useMemo(() => ({
    setState,
    state,
  }), [state]);

  return (
    <InstallationsContextBlocks.Provider
      value={value}
      {...props}
    />
  );
}

function useInstallationsBlocks() {
  const context = useContext(InstallationsContextBlocks);
  if (!context) {
    throw new Error('InstallationsContextBlocks deve ser usado dentro de um InstallationsProvider');
  }

  const { state, setState } = context;
  const { publisher, setRequestError } = useGlobal();

  const setPublisherData = useCallback((publisherData) => {
    setState((prevState) => ({
      ...prevState,
      publisherData,
    }));
  }, [setState]);

  const getPublisher = useCallback(async () => {
    try {
      const response = await getPublisherData(publisher?.id);

      if (response) {
        setPublisherData(response);
        return response;
      }
    } catch (error) {
      if (error?.response?.status) {
        setRequestError();
      }
    }
  }, [publisher, setPublisherData, setRequestError]);

  const setManualSteps = useCallback((manualSteps, position, status) => {
    const newArray = manualSteps;
    newArray[position].status = status;
    setState((prevState) => ({
      ...prevState,
      newArray,
    }));
  }, [setState]);

  const setPublisherStageBlocks = (publisherStageBlocks) => {
    setState((prevState) => ({
      ...prevState,
      publisherStageBlocks,
    }));
  };
  const postPublisherStageBlocks = async () => {
    try {
      const response = await postStageUpdateBlocks();
      if (response != null) {
        setPublisherStageBlocks(true);
      }
    } catch (error) {
      if (error?.response?.status) {
        setRequestError();
      }
    }
  };
  return {
    publisherData: state.publisherData,
    getPublisher,
    manualSteps: state.manualSteps,
    setManualSteps,
    publisherStageBlocks: state.postPublisherStageBlocks,
    postPublisherStageBlocks,
    publisherCheckApprovalUrl: state.postPublisherCheckApprovalUrl,
  };
}

export { InstallationsProviderBlocks, useInstallationsBlocks };

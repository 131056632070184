/* eslint-disable no-unused-vars */
import { ajax } from '../utils/request';
import { APPLICATION } from '../constants';

export const urlParams = new URLSearchParams(window.location.search);
export const autoservice = urlParams.get('utm_source');

export const getValidHash = (token) => ajax.get('/checkToken', {
  headers: {
    'x-token': `${token}`,
  },
}).text();

export const getCardsStatus = (token, publisherId) => ajax.get(`${APPLICATION.API_URL}/publisher/${publisherId}`, {
  headers: {
    'x-token': `${token}`,
  },
}).json();

export const getPublisherByToken = () => ajax.get(`${APPLICATION.API_URL}/entities`, {
  headers: {
    'x-token': window.localStorage.getItem('token'),
  },
}).json();

export const getDevelopmentToken = async (url) => {
  const searchParams = new URLSearchParams();
  searchParams.set('exp', 7 * 24 * 60 * 60);

  const token = btoa(`${process.env.REACT_APP_DEV_USERNAME}:${process.env.REACT_APP_DEV_PASS}`);
  return ajax.post(`${APPLICATION.DASH_URL}/getToken`, {
    body: searchParams,
    headers: {
      Authorization: `Basic ${token}`,
      withCredentials: true,
    },
  }).text();
};

export const getVerifyUser = (email) => ajax.get(`${APPLICATION.API_URL}/verifyuser/${email}`, {
  headers: {
    HTTP_X_TOKEN: `${window.localStorage.getItem('token')}`,
  },
}).json();
